/***************************************************************************************************
 *
 * This file manages the Redux slice for handling products and lead times.
 * 
 * It provides:
 * 
 * 1. Async actions to fetch products, lead times, and products with lead times, either globally 
 *    or filtered by user.
 * 2. Actions to create and update lead times.
 * 3. A reducer to handle the state updates for products, lead times, and API responses.
 * 4. A reset action to clear the state when needed.
 * 
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getProducts, getProductsWithLeadTimes, getLeadTimes, createLeadTime, updateLeadTime, getProductsWithLeadTimesByUser } from 'src/_helpers/api'

const name = 'products'

const initialState = createInitialState()

const getProductsAsync = createAsyncThunk(`${name}/get-products`, async () => {
  const response = await getProducts()
  return response
})

const getProductsWithLeadTimesAsync = createAsyncThunk(`${name}/get-products-with-lead-times`, async () => {
  const response = await getProductsWithLeadTimes()
  return response
})

const getProductsWithLeadTimesByUserAsync = createAsyncThunk(`${name}/get-products-with-lead-times-by-user`, async (userId) => {
  const response = await getProductsWithLeadTimesByUser(userId)
  return response
})

const getLeadTimesAsync = createAsyncThunk(`${name}/get-lead-times`, async () => {
  const response = await getLeadTimes();
  return response;
});

const createLeadTimeAsync = createAsyncThunk(`${name}/create-lead-time`, async (leadTimeData) => {
  const response = await createLeadTime(leadTimeData);
  return response;
});

const updateLeadTimeAsync = createAsyncThunk(`${name}/update-lead-time`, async ({ id, ...leadTimeData }) => {
  const response = await updateLeadTime(id, leadTimeData);
  return response;
});

const productSlice = createSlice({
  name,
  initialState: createInitialState(), 
  reducers: {
    resetState: (state) => {
      state.productsUser = [];
      state.error = null;
    },
  },  extraReducers: (builder) => {
    builder

      .addCase(getProductsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        state.products = action.payload.data
      })
      .addCase(getProductsAsync.rejected, (state, action) => {
        state.error = action.error
      })

      .addCase(getProductsWithLeadTimesAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getProductsWithLeadTimesAsync.fulfilled, (state, action) => {
        state.productsUser = action.payload.data
      })
      .addCase(getProductsWithLeadTimesAsync.rejected, (state, action) => {
        state.error = action.error
      })

      .addCase(getProductsWithLeadTimesByUserAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getProductsWithLeadTimesByUserAsync.fulfilled, (state, action) => {
        state.productsUser = action.payload.data
      })
      .addCase(getProductsWithLeadTimesByUserAsync.rejected, (state, action) => {
        state.error = action.error
      })

      .addCase(getLeadTimesAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(getLeadTimesAsync.fulfilled, (state, action) => {
        state.leadTimes = action.payload.data;
      })
      .addCase(getLeadTimesAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(createLeadTimeAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(createLeadTimeAsync.fulfilled, (state, action) => {
        state.leadTimes.push(action.payload.data);
      })
      .addCase(createLeadTimeAsync.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(updateLeadTimeAsync.pending, (state) => {
        state.error = null;
      })
      .addCase(updateLeadTimeAsync.fulfilled, (state, action) => {
        //console.log("Update Lead Time Response:", action.payload);
        
        if (action.payload && action.payload.data && action.payload.data.LeadTimeID) {
          const index = state.leadTimes.findIndex(lt => lt.LeadTimeID === action.payload.data.LeadTimeID);
          if (index !== -1) {
            state.leadTimes[index] = action.payload.data;
          } else {
            //console.error('LeadTimeID not found in state');
          }
          //console.log("LeadTime received in updateLeadTimeAsync")
        }
      })
      .addCase(updateLeadTimeAsync.rejected, (state, action) => {
        state.error = action.error;
      });
      
  },
})

function createInitialState() {
  return {
    products: [],
    productsUser: [], 
    leadTimes: [],
    error: null,
  }
}

export const productActions = {
  ...productSlice.actions,
  getProductsAsync,
  getProductsWithLeadTimesAsync,
  getProductsWithLeadTimesByUserAsync,
  getLeadTimesAsync,  
  createLeadTimeAsync,
  updateLeadTimeAsync,
  resetState: productSlice.actions.resetState,}
export const productsReducer = productSlice.reducer

/***************************************************************************************************
* 
* This file handles token expiration checks and status utility functions. It includes:
* 
* 1. A function to verify the expiration of an authentication token and log out the user if expired.
* 2, Functions to return corresponding badge colors and status names based on user status values.
* 
***************************************************************************************************/

import { history } from './history'
import { authActions } from '../_store'
import Cookies from 'js-cookie';


export const checkTokenExpiration = (dispatch) => {
  const token = Cookies.get('auth_token');  
  const tokenExpiration = Cookies.get('token_expiration');  
  
  if (!token) {
    //console.log("!-- No token, redirecting to login !--");
    dispatch(authActions.logout());
    return null;
  }

  const now = new Date().getTime(); 

  //console.log("NOW: ", now);
  //console.log("tokenExpiration: ", tokenExpiration);

  if (!localStorage.getItem("stayLoggedIn")) {
    if (now > tokenExpiration) {
      //console.log("!-- Token expired, redirecting to login !--");
      Cookies.remove('auth_token');  
      Cookies.remove('token_expiration');
      dispatch(authActions.logout());
      return null;
    } else {
      //console.log("Token is still valid!");
    }
  }
}

export const getBadgeColor = (status) => {
  switch (status) {
    case '1':
    case 'Active':
      return 'success'
    case '0':
    case 'Inactive':
      return 'warning'
    case '2':
    case 'Pending':
      return 'secondary'
    case '3':
    case 'Baned':
      return 'danger'
    default:
      return 'primary'
  }
}

export const getStatusName = (status) => {
  switch (status) {
    case '1':
      return 'Active'
    case '0':
      return 'Inactive'
    case '2':
      return 'Pending'
    case '3':
      return 'Baned'
    default:
      return status
  }
}

/***************************************************************************************************
 *
 * This file defines the Redux slice for managing projects within the application.
 * 
 * It provides:
 * 
 * 1. Async actions to create, update, and fetch projects.
 * 2. A reducer to handle state changes related to project data and API responses.
 * 3. Initial state setup for storing project data.
 *
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { createProject, getProjects, updateProject } from 'src/_helpers/api'

// create slice

const name = 'projects'

const initialState = createInitialState()

const addProjectAsync = createAsyncThunk(`${name}/add-project`, async (projectData) => {
  //console.log('🚀 ~ addProjectAsync ~ projectData:', projectData)
  let response
  if (projectData.id) {
    response = await updateProject(projectData)
  } else {
    response = await createProject(projectData)
  }
  return response
})

const getAllProjectsAsync = createAsyncThunk(`${name}/get-projects`, async () => {
  const response = await getProjects()
  return response
})

const projectSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addProjectAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addProjectAsync.fulfilled, (state, action) => {
        state.project = action.payload
      })
      .addCase(addProjectAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllProjectsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllProjectsAsync.fulfilled, (state, action) => {
        state.projects = action.payload.data
      })
      .addCase(getAllProjectsAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    projects: [],
  }
}

export const projectActions = {
  ...projectSlice.actions,
  getAllProjectsAsync,
  addProjectAsync,
}
export const projectsReducer = projectSlice.reducer

/***************************************************************************************************
 *
 * Main application routing and authentication handling.
 * 
 * Features:
 * 1. Lazy loading for performance optimization of routes and components.
 * 2. Routes for login, registration, password reset, and error pages.
 * 3. Authentication checks, including token expiration and temporary password handling.
 * 4. Dispatches location-related actions to fetch cities, countries, and states.
 *
 * Utilizes React Router for navigation and Redux for state management.
 ***************************************************************************************************/

import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector  } from 'react-redux'
import { authActions } from "../src/_store";
import './scss/style.scss'
import { history } from './_helpers/history'
import { checkTokenExpiration } from './_helpers/utils'
import { locationActions } from 'src/_store'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'))
const ForgetPassword = React.lazy(() => import('./views/pages/forgetPassword/ForgetPassword'))

// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

const App = () => {
  const dispatch = useDispatch()
  const authUser = useSelector(state => state.auth.user)
  const location = useLocation()
  const navigate = useNavigate()
  history.navigate = useNavigate()
  history.location = useLocation()

  useEffect(() => {
    if (location.pathname !== "/forget-password" && location.pathname !== "/login") {
     checkTokenExpiration(dispatch);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (sessionStorage.getItem("tempPassword") === "true") {
    //  if(authUser.password_expiration_date < new Date())
      //console.log("TEMP PASSWORD IN AUTHUSER: ");
      history.navigate("/reset-password");
    //  } 
    }
      }, [authUser, location.pathname]);

  /*
  useEffect(() => {
    dispatch(locationActions.getCitiesAsync())
    dispatch(locationActions.getCountriesAsync())
    dispatch(locationActions.getStatesAsync())
  }, [])
  */

//console.log("APP CHECK: ", location.pathname, authUser)

  return (
    // <HashRouter>
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/forget-password" name="Forget Password" element={<ForgetPassword />} />
        <Route exact path="/reset-password" name="Reset Password" element={<ResetPassword />} />
        <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
    // </HashRouter>
  )
}

export default App

/***************************************************************************************************
 * 
 * This file defines the Redux slice for managing submittal revisions within the application.
 * 
 * It includes:
 * 
 * 1. An async action to fetch all submittal revisions from the API.
 * 2. A reducer to manage state updates for submittal revisions based on API responses.
 * 3. Initial state setup for storing revision data and error handling.
 * 
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getSubmittalsRevisions } from 'src/_helpers/api'

const name = 'revisions'

const initialState = createInitialState()

const getAllSubmittalRevisionssAsync = createAsyncThunk(
  `${name}/get-submittal-revisions`,
  async () => {
    return await getSubmittalsRevisions()
  },
)

const revisionsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubmittalRevisionssAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllSubmittalRevisionssAsync.fulfilled, (state, action) => {
        state.revisions = action.payload.data
        //console.log('🚀 ~ getAllSubmittalRevisionssAsync.fulfilled ~ action:', action)
      })
      .addCase(getAllSubmittalRevisionssAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    revisions: [],
  }
}

export const revisionsActions = {
  ...revisionsSlice.actions,
  getAllSubmittalRevisionssAsync,
}
export const revisionsReducer = revisionsSlice.reducer

/***************************************************************************************************
 *
 * This file manages the Redux slice for handling system parameters.
 * 
 * It provides:
 * 
 * 1. Async actions to add, update, and fetch parameters using Redux Toolkit's `createAsyncThunk`.
 * 2. A reducer that handles the state changes for pending, fulfilled, and rejected API requests.
 * 3. The ability to store and manage parameters in the Redux store, updating the state based on the API responses.
 * 4. Actions to trigger these state updates and manage the parameters efficiently.
 * 
***************************************************************************************************/


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createParameter, getParameters, updateParameter } from 'src/_helpers/api'

const name = 'parameters'

const initialState = createInitialState()

const addParametereAsync = createAsyncThunk(`${name}/add-parameter`, async (parameterData) => {
  return await createParameter(parameterData)
})

const updateParametereAsync = createAsyncThunk(
  `${name}/update-parameter`,
  async (parameterData) => {
    return await updateParameter(parameterData)
  },
)

const getAllParametersAsync = createAsyncThunk(`${name}/get-parameters`, async () => {
  return await getParameters()
})

const parameterSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addParametereAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addParametereAsync.fulfilled, (state, action) => {
        state.template = action.payload
      })
      .addCase(addParametereAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(updateParametereAsync.pending, (state) => {
        state.error = null
      })
      .addCase(updateParametereAsync.fulfilled, (state, action) => {
        state.template = action.payload
      })
      .addCase(updateParametereAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllParametersAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllParametersAsync.fulfilled, (state, action) => {
        //console.log('🚀 ~ .addCase ~ action:', action)
        state.parameters = action.payload.data
      })
      .addCase(getAllParametersAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    parameters: [],
  }
}

export const parameterActions = {
  ...parameterSlice.actions,
  getAllParametersAsync,
  addParametereAsync,
  updateParametereAsync,
}
export const parametersReducer = parameterSlice.reducer

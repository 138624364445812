/***************************************************************************************************
 * 
 * This file manages the Redux slice for handling location-related data, including countries, states, and cities.
 * 
 * It allows:
 * 
 * 1. Fetching country, state, and city data asynchronously using Redux Toolkit's `createAsyncThunk`.
 * 2. Managing the state for location data (cities, states, countries) within the Redux store.
 * 3. Handling pending, fulfilled, and rejected states for each API call to keep track of errors and data loading.
 * 4. Exposing actions to trigger the API calls and update the Redux state accordingly.
 *
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getCities, getCountries, getStates } from 'src/_helpers/api'

const name = 'locations'

const initialState = createInitialState()

const getCountriesAsync = createAsyncThunk(`${name}/get-countries`, async () => {
  const response = await getCountries()
  return response
})

const getStatesAsync = createAsyncThunk(`${name}/get-states`, async () => {
  const response = await getStates()
  return response
})

const getCitiesAsync = createAsyncThunk(`${name}/get-cities`, async () => {
  const response = await getCities()
  return response
})

const locationSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCitiesAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getCitiesAsync.fulfilled, (state, action) => {
        //console.log(action)
        state.locations.cities = action.payload.data
      })
      .addCase(getCitiesAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getCountriesAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getCountriesAsync.fulfilled, (state, action) => {
        //console.log(action)
        state.locations.countries = action.payload.data
      })
      .addCase(getCountriesAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getStatesAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getStatesAsync.fulfilled, (state, action) => {
        //console.log(action)
        state.locations.states = action.payload.data
      })
      .addCase(getStatesAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    locations: {
      cities: [],
      states: [],
      countries: [],
    },
  }
}

export const locationActions = {
  ...locationSlice.actions,
  getCountriesAsync,
  getCitiesAsync,
  getStatesAsync,
}
export const locationsReducer = locationSlice.reducer

/***************************************************************************************************
*
* This file handles company-related actions and state using Redux Toolkit.
* 
* It allows:
* 
* 1. Asynchronous actions to add, update, and fetch companies via API calls.
* 2. Managing the Redux state for companies, including error handling and resetting state.
* 3. Centralized control of the list of companies, enabling components to access and update company data.
* 
* Redux Toolkit simplifies Redux by combining actions, reducers, and async logic in one structure.
*
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createCompany, getCompanies, updateCompany } from 'src/_helpers/api'


const name = 'companies'

const initialState = createInitialState()

const addCompanyAsync = createAsyncThunk(`${name}/add-company`, async (companyData) => {
  return await createCompany(companyData)
})

const updateCompanyAsync = createAsyncThunk(`${name}/update-company`, async (companyData) => {
  return await updateCompany(companyData)
})

const getAllCompaniesAsync = createAsyncThunk(`${name}/get-companies`, async () => {
  return await getCompanies()
})

const companySlice = createSlice({
  name,
  initialState: createInitialState(), 
  reducers: {
    resetState: (state) => {
      state.companies = []; 
      state.error = null; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCompanyAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addCompanyAsync.fulfilled, (state, action) => {
        state.template = action.payload
      })
      .addCase(addCompanyAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(updateCompanyAsync.pending, (state) => {
        state.error = null
      })
      .addCase(updateCompanyAsync.fulfilled, (state, action) => {
        state.template = action.payload
      })
      .addCase(updateCompanyAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllCompaniesAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllCompaniesAsync.fulfilled, (state, action) => {
        //console.log('🚀 ~ .addCase ~ action:', action)
        state.companies = action.payload.data || []; 
      })
      .addCase(getAllCompaniesAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    companies: [],
  }
}

export const companyActions = {
  ...companySlice.actions,
  getAllCompaniesAsync,
  addCompanyAsync,
  updateCompanyAsync,
  resetState: companySlice.actions.resetState,}
export const companiesReducer = companySlice.reducer

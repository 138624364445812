/***************************************************************************************************
 * This file handles API calls to interact with the Python backend.
 * It allows for:
 * 
 * 1. Adding and retrieving quotations and submittals.
 * 2. Sending form data to the backend for processing.
 * 3. Updating and fetching real-time information via HTTP requests.
 * 
 * These methods facilitate communication between the React frontend and the Python backend.
 ***************************************************************************************************/

import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

/*Hamid 2024-10-25*/
// Configuration de base d'axios
axios.defaults.baseURL = apiUrl;


export { setupAxiosInterceptors };

function setupAxiosInterceptors() {
  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {

      }
      return Promise.reject(error);
    }
  );
}

export function authenticate(userData) {
  //console.log("Attempting to authenticate user...");

  return axios
    .post(`${apiUrl}/api/auth/authenticate`, userData)
    .then((response) => {
      //console.log("Authentication successful, response:", response);
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        //console.error("Server responded with error status:", error.response.status);
        //console.error("Error response data:", error.response.data);

        if (error.response.status === 500) {
          //console.error("Internal Server Error - Possible backend issue.");
        } else if (error.response.status === 404) {
          //console.error("API endpoint not found - Check backend route.");
        } else if (error.response.status === 403) {
          //console.error("Access forbidden - Possibly due to authentication/authorization failure.");
        }
      } else if (error.request) {
        //console.error("No response from server - Check if backend is reachable.");
        //console.error("Request details:", error.request);
      } else {
        //console.error("Error setting up the request:", error.message);
      }
      return Promise.reject(error.response ? error.response.data : error.message);
    });
}


export function sendPassword(email) {
  return axios
    .post(`${apiUrl}/api/auth/reset-password-with-email`, { email })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function resetPassword(userId) {
  return axios
    .post(`${apiUrl}/api/auth/reset-password`, { userId })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function setNewPassword({ password, userId /*, token */ }) {
  return axios
    .post(`${apiUrl}/api/auth/set-password`, { password, userId /*, token */ })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function updatePassword({
  currentPassword,
  password,
  userId /*, token */,
}) {
  return axios
    .post(`${apiUrl}/api/auth/update-password`, {
      currentPassword,
      password,
      userId /*, token */,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function createUser(userData) {
  return axios
    .post(`${apiUrl}/api/users`, userData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function updateUser(userData) {
  return axios
    .put(`${apiUrl}/api/users/${userData.id}`, userData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getUser(id) {
  //console.log("GETTING USER'S INFO...");
  return axios
    .get(`${apiUrl}/api/users/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getUsers() {
  return axios
    .get(`${apiUrl}/api/users`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function desactivateUser(id) {
  return axios
    .put(`${apiUrl}/api/users/desactivate/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function reactivateUser(id) {
  return axios
    .put(`${apiUrl}/api/users/reactivate/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function deleteUser(id) {
  return axios
    .delete(`${apiUrl}/api/users/delete/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function checkUserQuotationAndRevisions(id) {
  /*console.log(
    `Making API call to check quotations and revisions for user ID: ${id}`
  );*/
  return axios
    .get(`${apiUrl}/api/users/checkIfHasQuotationORRevision/${id}`)
    .then((response) => {
      //console.log("API Response:", response.data);
      return response.data;
    })
    .catch((error) => {
      //console.log("API Error:", error);
      return Promise.reject(error);
    });
}

export function createTemplate(templateData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios
    .post(`${apiUrl}/api/templates/upload`, templateData, config)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function reactivateTemplate(templateData) {
  return axios
    .post(`${apiUrl}/api/templates/reactivate`, templateData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function updateTemplate(templateData) {
  return axios
    .put(`${apiUrl}/api/templates/${templateData.id}`, templateData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getTemplates() {
  return axios
    .get(`${apiUrl}/api/templates`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getProducts() {
  return axios
    .get(`${apiUrl}/api/products`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getProductById(productId) {
  return axios
    .get(`${apiUrl}/api/products/${productId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getProductsWithLeadTimes() {
  return axios
    .get(`${apiUrl}/api/products-with-lead-times`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getProductsWithLeadTimesByUser(userId) {
  return axios
    .get(`${apiUrl}/api/products-with-lead-times/user/${userId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getLeadTimes() {
  return axios
    .get(`${apiUrl}/api/lead-times`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getLeadTimesById(leadTimeId) {
  return axios
    .get(`${apiUrl}/api/lead-times/${leadTimeId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function createLeadTime(leadTimeData) {
  return axios
    .post(`${apiUrl}/api/lead-times`, leadTimeData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function updateLeadTime(leadTimeId, leadTimeData) {
  //console.log("Method in API to update lead time called with ID:", leadTimeId);
  return axios
    .put(`${apiUrl}/api/lead-times/${leadTimeId}`, leadTimeData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getProjects() {
  return axios
    .get(`${apiUrl}/api/projects`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function createProject(projectData) {
  return axios
    .post(`${apiUrl}/api/projects`, projectData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function updateProject(projectData) {
  return axios
    .put(`${apiUrl}/api/projects/${projectData.id}`, projectData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getQuotations() {
  return axios
    .get(`${apiUrl}/api/quotations`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getQuotation(id) {
  return axios
    .get(`${apiUrl}/api/quotations/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function createQuotation(quotationData) {
  return axios
    .post(`${apiUrl}/api/quotations`, quotationData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function createSubmittal(submitalData) {
  return axios
    .post(`${apiUrl}/api/submittals`, submitalData /*, { timeout: 10000 }*/)
    .then((response) => {
      //console.log("Received response from API:", response);
      return response.data;
    })
    .catch((error) => {
      //console.error("Error during API request:", error);

      if (error.response) {
        //console.error("Error response data:", error.response.data);
        //console.error("Error response status:", error.response.status);
        //console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        /*console.error(
          "No response received. Error request data:",
          error.request
        );
        */
      } else {
        //console.error("Unexpected error:", error.message);
      }

      return Promise.reject(error);
    });
}

export function getSubmittals() {
  return axios
    .get(`${apiUrl}/api/submittals`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getSubmittalsByProduct(productId) {
  return axios
    .get(`${apiUrl}/api/submittals/${productId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getSubmittalsByProductAndUserId(productId, userId) {
  return axios
    .get(`${apiUrl}/api/submittals/${productId}/${userId}`)
    .then((response) => {
      //console.log("Raw submittals data:", response.data); // Log the raw data here
      return response.data;
    })
    .catch((error) => Promise.reject(error));
}

export function getSubmittalsRevisions() {
  return axios
    .get(`${apiUrl}/api/submittals/revisions`)
    .then((response) => {
      //console.log("Raw revisions data:", response.data); // Log raw data
      return response.data;
    })
    .catch((error) => Promise.reject(error));
}

export function getSubmittalById(id) {
  return axios
    .get(`${apiUrl}/api/submittalsbyid/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

///////////// AJOUT /////////////
export function getQuotationsAndRevisions(userId) {
  return axios
    .get(`${apiUrl}/api/submittals/quotations-and-revisions/${userId}`)
    .then((response) => response.data)
    .catch((error) => {
      //console.error("Error fetching quotations and revisions:", error);
      throw error;
    });
}

///////////// AJOUT /////////////
export function getRelatedRecords() {
  return axios
    .get(`${apiUrl}/api/submittals/related-records`)
    .then((response) => response.data)
    .catch((error) => {
      //console.error("Error fetching related records:", error);
      throw error;
    });
}

export function reactivateSubmittal(id) {
  return axios
    .post(`${apiUrl}/api/quotations/activate`, id)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function desactivateSubmittal(id) {
  return axios
    .post(`${apiUrl}/api/quotations/desactivate`, id)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function updateQuotation(quotationData) {
  return axios
    .post(`${apiUrl}/api/revision`, quotationData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getParameters() {
  return axios
    .get(`${apiUrl}/api/parameters`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function createParameter(parameterData) {
  return axios
    .post(`${apiUrl}/api/parameters`, parameterData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function updateParameter(parameterData) {
  return axios
    .put(`${apiUrl}/api/parameters`, parameterData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getParameter(id) {
  return axios
    .get(`${apiUrl}/api/parameters/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getNotifications() {
  return axios
    .get(`${apiUrl}/api/notifications/`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getFile(fileData) {
  return axios
    .post(`${apiUrl}/api/download`, fileData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getCities() {
  return axios
    .get(`${apiUrl}/api/locations/cities`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getStates() {
  return axios
    .get(`${apiUrl}/api/locations/states`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getCountries() {
  return axios
    .get(`${apiUrl}/api/locations/countries`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function createCompany(companyData) {
  return axios
    .post(`${apiUrl}/api/companies`, companyData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function updateCompany(companyData) {
  return axios
    .put(`${apiUrl}/api/companies`, companyData)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export function getCompanies() {
  return axios
    .get(`${apiUrl}/api/companies`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

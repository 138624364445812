/***************************************************************************************************
 * 
 * This file defines the Redux slice for managing form step states within the application.
 * 
 * It includes:
 * 
 * 1. Initial state setup for tracking the current step in different forms (quotations, templates, users).
 * 2. Reducers to update the form step values for quotations, templates, and users based on user actions.
 * 
***************************************************************************************************/

import { createSlice } from '@reduxjs/toolkit'

const name = 'steps'
const initialState = {
  quotationsFormStep: 1,
  templatesFormStep: 1,
  usersFormStep: 1,
}

const stepsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setQuotationsFormStep: (state, action) => {
      state.quotationsFormStep = action.payload
    },
    setTemplatesFormStep: (state, action) => {
      state.templatesFormStep = action.payload
    },
    setUsersFormStep: (state, action) => {
      state.usersFormStep = action.payload
    },
  },
})

export const { setQuotationsFormStep, setTemplatesFormStep, setUsersFormStep } = stepsSlice.actions
export const stepsReducer = stepsSlice.reducer

/***************************************************************************************************
 * 
 * This file defines the Redux slice for managing templates in the application.
 * 
 * It includes:
 * 
 * 1. Async thunks for creating, updating, reactivating, and retrieving templates.
 * 2. Reducers to handle pending, fulfilled, and rejected states for the API calls related to templates.
 * 3. Initial state management for storing templates and handling errors during API interactions.
 * 
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { createTemplate, getTemplates, updateTemplate, reactivateTemplate } from 'src/_helpers/api'

const name = 'templates'

const initialState = createInitialState()

const addTemplateAsync = createAsyncThunk(`${name}/add-template`, async (templateData) => {
  //console.log('🚀 ~ addTemplateAsync ~ templateData:', templateData)
  let response
  if (templateData.id) {
    response = await updateTemplate(templateData)
  } else {
    response = await createTemplate(templateData)
  }
  return response
})

const reactivateTemplateAsync = createAsyncThunk(
  `${name}/reactivate-template`,
  async (templateData) => {
    return await reactivateTemplate(templateData)
  },
)

const getAllTemplatesAsync = createAsyncThunk(`${name}/get-templates`, async () => {
  const response = await getTemplates()
  return response
})

const templateSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addTemplateAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addTemplateAsync.fulfilled, (state, action) => {
        state.template = action.payload
      })
      .addCase(addTemplateAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllTemplatesAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllTemplatesAsync.fulfilled, (state, action) => {
        state.templates = action.payload.data
      })
      .addCase(getAllTemplatesAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    templates: [],
  }
}

export const templateActions = {
  ...templateSlice.actions,
  getAllTemplatesAsync,
  addTemplateAsync,
  reactivateTemplateAsync,
}
export const templatesReducer = templateSlice.reducer

/***************************************************************************************************
 * 
 * This file defines the Redux slice for managing the UI state in the application.
 * 
 * It includes:
 * 
 * 1. Initial state values for sidebar visibility, aside visibility, and theme selection.
 * 2. Reducers to handle changes in sidebar visibility, aside visibility, and theme.
 * 3. Exported actions to update the UI state.
 * 
***************************************************************************************************/

import { createSlice } from '@reduxjs/toolkit'

const name = 'ui'
const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
}

const uiSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload
    },
    setAsideShow: (state, action) => {
      state.asideShow = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
  },
})

export const { setSidebarShow, setAsideShow, setTheme } = uiSlice.actions
export const uiActions = { ...uiSlice.actions, setSidebarShow, setAsideShow, setTheme }
export const uiReducer = uiSlice.reducer


/*************************************************************************************************** *
*
* This file defines a custom history object to manage navigation outside React components.
* It includes:
*
* 1. navigate: A method placeholder for programmatic navigation outside of React components.
* 2. location: A reference to track the current location or path.
*
***************************************************************************************************/

export const history = {
  navigate: null,
  location: null,
}

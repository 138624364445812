/***************************************************************************************************
 * This file manages the Redux slice for handling notifications data.
 * 
 * It allows:
 * 
 * 1. Fetching notifications asynchronously from an API using Redux Toolkit's `createAsyncThunk`.
 * 2. Managing the state for notifications within the Redux store, keeping track of pending, fulfilled, and rejected states.
 * 3. Handling the storage of notifications in the Redux store and updating error states if any issues arise.
 * 4. Exposing actions to trigger the API call and update the Redux state with the received notifications data.
 * 
***************************************************************************************************/

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getNotifications } from 'src/_helpers/api'

const name = 'notifications'

const initialState = createInitialState()

const getNotificationsAsync = createAsyncThunk(`${name}/get-notifications`, async () => {
  const response = await getNotifications()
  return response
})

const productSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getNotificationsAsync.fulfilled, (state, action) => {
        state.notifications = action.payload.data
      })
      .addCase(getNotificationsAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    notifications: [],
  }
}

export const productActions = {
  ...productSlice.actions,
  getNotificationsAsync,
}
export const notificationsReducer = productSlice.reducer

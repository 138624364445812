/***************************************************************************************************
 *
 * This file defines the Redux slice for managing quotations within the application.
 * 
 * It provides:
 * 
 * 1. Async actions to create, update, and fetch quotations.
 * 2. A reducer to handle state changes related to quotation data and API responses.
 * 3. Initial state setup for storing quotation data.
 * 
***************************************************************************************************/


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { createQuotation, getQuotations, updateQuotation } from 'src/_helpers/api'


const name = 'quotations'

const initialState = createInitialState()

const addQuotationAsync = createAsyncThunk(`${name}/add-quotation`, async (quotationData) => {
  const today = new Date()
  const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`

  const apiData = {
    AccuCore: quotationData.accuCore,
    AccessControl: quotationData.accessControl,
    AirFlowType: quotationData.airFlowType,
    Arrangement: quotationData.arrangement,
    City:
      quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
        ? quotationData.city
        : null,
    Communication: quotationData.communication,
    ContactName: quotationData.contactName,
    Controls: quotationData.controls,
    Country:
      quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
        ? quotationData.country
        : null,
    Customer: quotationData.customer,
    Elevation: quotationData.elevation,
    ExtraSetFilters: quotationData.extraSetFilters,
    FiltersLevel: quotationData.filtersLevel,
    FiltersType: quotationData.filtersType,
    Location: quotationData.location,
    Margin: quotationData.margin,
    Material: quotationData.material,
    MERVFreshReturnAirFilters: 'Null',
    MotorOversizeFactor: quotationData.motorOversizeFactor,
    NumberUnits: quotationData.numberUnits,
    OAEADamper: quotationData.oaeaDamper,
    ProductName: quotationData.productName,
    Product_id: quotationData.product_id,
    ProjectName: quotationData.projectName,
    ProjectNumber: quotationData.projectNumber,
    Province:
      quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
        ? quotationData.province
        : null,
    QuotationStatus: quotationData.quotationStatus,
    QuoteDate: formattedDate,
    Representative: quotationData.representative,
    ReturnAirESP: quotationData.returnAirESP,
    ReturnAirHighVolume: quotationData.returnAirHighVolume,
    ReturnAirMinVolume: quotationData.returnAirMinVolume,
    RoofCurb: 'NotIncluded',
    Shipping: quotationData.shipping,
    StartUp: quotationData.startUp,
    SummerConditionsOutdoorTdB: quotationData.summerConditionsOutdoorTdB,
    SummerConditionsOutdoorTwB: quotationData.summerConditionsOutdoorTwB,
    SummerConditionsReturnTdB: quotationData.summerConditionsReturnTdB,
    SummerConditionsReturnTwB: quotationData.summerConditionsReturnTwB,
    SupplyAirESP: quotationData.supplyAirESP,
    SupplyAirHighVolume: quotationData.supplyAirHighVolume,
    SupplyAirMinVolume: quotationData.supplyAirMinVolume,
    Template_id: quotationData.template_id,
    UnitsTag: quotationData.unitsTag,
    UserId: quotationData.UserId,
    Voltage: quotationData.voltage,
    WinterConditionsOutdoorTdB: quotationData.winterConditionsOutdoorTdB,
    WinterConditionsOutdoorTwB: quotationData.winterConditionsOutdoorTwB,
    WinterConditionsReturnTdB: quotationData.winterConditionsReturnTdB,
    WinterConditionsReturnTwB: quotationData.winterConditionsReturnTwB,
  }

  //console.log('🚀 ~ addQuotationAsync ~ quotationData:', apiData)
  let response

  /////////////// IF ITS A REVISON CREATION ///////////////
  if (quotationData.id) {
    const apiData = {
      ABEQuotationCode: quotationData.quote,
      AccuCore: quotationData.accuCore,
      AccessControl: quotationData.accessControl,
      AirFlowType: quotationData.airFlowType,
      Arrangement: quotationData.arrangement,
      City:
        quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
          ? quotationData.city
          : null,
      Communication: quotationData.communication,
      ContactName: quotationData.contactName || null,
      Controls: quotationData.controls,
      Country:
        quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
          ? quotationData.country
          : null,
      Customer: quotationData.customer || "",
      Elevation: quotationData.elevation,
      ExtraSetFilters: quotationData.extraSetFilters,
      FiltersLevel: quotationData.filtersLevel,
      FiltersType: quotationData.filtersType,
      Location: quotationData.location,
      Margin: quotationData.margin,
      Material: quotationData.material,
      MERVFreshReturnAirFilters: 'Null',
      MotorOversizeFactor: quotationData.motorOversizeFactor,
      NumberUnits: quotationData.numberUnits,
      OAEADamper: quotationData.oaeaDamper,
      Product_id: quotationData.product_id, 
      ProjectName: quotationData.projectName,
      ProjectNumber: quotationData.projectNumber,
      ProductName: quotationData.productName, 
      Province:
        quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
          ? quotationData.province
          : null,
      QuotationStatus: quotationData.quotationStatus,
      Quote: quotationData.quote,
      QuoteDate: formattedDate,
      Representative: quotationData.representative || null,
      ReturnAirESP: quotationData.returnAirESP,
      ReturnAirHighVolume: quotationData.returnAirHighVolume,
      ReturnAirMinVolume: quotationData.returnAirMinVolume,
      RoofCurb: 'NotIncluded',
      Shipping: quotationData.shipping,
      StartUp: quotationData.startUp,
      SupplyAirESP: quotationData.supplyAirESP,
      SupplyAirHighVolume: quotationData.supplyAirHighVolume,
      SupplyAirMinVolume: quotationData.supplyAirMinVolume,
      Template_id: quotationData.template_id,
      UnitsTag: quotationData.unitsTag,
      UserId: quotationData.UserId,
      Voltage: quotationData.voltage,
      WinterConditionsOutdoorTdB: quotationData.winterConditionsOutdoorTdB,
      WinterConditionsOutdoorTwB: quotationData.winterConditionsOutdoorTwB,
      WinterConditionsReturnTdB: quotationData.winterConditionsReturnTdB,
      WinterConditionsReturnTwB: quotationData.winterConditionsReturnTwB,
    };
    
    response = await updateQuotation(apiData)

  } else {

    /////////////// IF ITS AN ORIGINAL SUBMISSION CREATION ///////////////
    const apiData = {
      AccuCore: quotationData.accuCore,
      AccessControl: quotationData.accessControl,
      AirFlowType: quotationData.airFlowType,
      Arrangement: quotationData.arrangement,
      City:
        quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
          ? quotationData.city
          : null,
      Communication: quotationData.communication,
      ContactName: quotationData.contactName || null,
      Controls: quotationData.controls,
      Country:
        quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
          ? quotationData.country
          : null,
      Customer: quotationData.customer || "",
      Elevation: quotationData.elevation,
      ExtraSetFilters: quotationData.extraSetFilters,
      FiltersLevel: quotationData.filtersLevel,
      FiltersType: quotationData.filtersType,
      Location: quotationData.location,
      Margin: quotationData.margin,
      Material: quotationData.material,
      MERVFreshReturnAirFilters: 'Null',
      MotorOversizeFactor: quotationData.motorOversizeFactor,
      NumberUnits: quotationData.numberUnits,
      OAEADamper: quotationData.oaeaDamper,
      ProductName: quotationData.productName,
      Product_id: quotationData.product_id,
      ProjectName: quotationData.projectName,
      ProjectNumber: quotationData.projectNumber,
      Province:
        quotationData.shipping !== 'NotIncluded' || quotationData.startup !== 'NotIncluded'
          ? quotationData.province
          : null,
      QuotationStatus: quotationData.quotationStatus,
      QuoteDate: formattedDate,
      Representative: quotationData.representative || null,
      ReturnAirESP: quotationData.returnAirESP,
      ReturnAirHighVolume: quotationData.returnAirHighVolume,
      ReturnAirMinVolume: quotationData.returnAirMinVolume,
      RoofCurb: 'NotIncluded',
      Shipping: quotationData.shipping,
      StartUp: quotationData.startUp,
      SummerConditionsOutdoorTdB: quotationData.summerConditionsOutdoorTdB,
      SummerConditionsOutdoorTwB: quotationData.summerConditionsOutdoorTwB,
      SummerConditionsReturnTdB: quotationData.summerConditionsReturnTdB,
      SummerConditionsReturnTwB: quotationData.summerConditionsReturnTwB,
      SupplyAirESP: quotationData.supplyAirESP,
      SupplyAirHighVolume: quotationData.supplyAirHighVolume,
      SupplyAirMinVolume: quotationData.supplyAirMinVolume,
      Template_id: quotationData.template_id,
      UnitsTag: quotationData.unitsTag,
      UserId: quotationData.UserId,
      Voltage: quotationData.voltage,
      WinterConditionsOutdoorTdB: quotationData.winterConditionsOutdoorTdB,
      WinterConditionsOutdoorTwB: quotationData.winterConditionsOutdoorTwB,
      WinterConditionsReturnTdB: quotationData.winterConditionsReturnTdB,
      WinterConditionsReturnTwB: quotationData.winterConditionsReturnTwB,
  };
    //console.log('QDDD : ' + quotationData)
    response = await createQuotation(apiData)
  }
  return response
})

const getAllQuotationsAsync = createAsyncThunk(`${name}/get-quotations`, async () => {
  const response = await getQuotations()
  //console.log('🚀 ~ returncreateAsyncThunk ~ response:', response)
  return response
})

const quotationSlice = createSlice({
  name,
  initialState: createInitialState(), 
  reducers: {
    resetState: (state) => {
      state.quotations = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addQuotationAsync.pending, (state) => {
        state.error = null
      })
      .addCase(addQuotationAsync.fulfilled, (state, action) => {
        state.quotations = action.payload
      })
      .addCase(addQuotationAsync.rejected, (state, action) => {
        state.error = action.error
      })
      .addCase(getAllQuotationsAsync.pending, (state) => {
        state.error = null
      })
      .addCase(getAllQuotationsAsync.fulfilled, (state, action) => {
        state.quotations = action.payload
      })
      .addCase(getAllQuotationsAsync.rejected, (state, action) => {
        state.error = action.error
      })
  },
})

function createInitialState() {
  return {
    quotations: [],
  }
}

export const quotationActions = {
  ...quotationSlice.actions,
  getAllQuotationsAsync,
  addQuotationAsync,
  resetState: quotationSlice.actions.resetState,}

export const quotationsReducer = quotationSlice.reducer
